export const EnableLogs = false
// Test Urls starts //

/* export const URL = 'https://s20q1.mycw.in/cw-retail/graphql'
export const GENERICURL ='https://s20q1.mycw.in/back-office-2.0/graphql'
export const Auth = 'https://s20q1.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://s20q1.mycw.in:8443/' */

// Test Urls ends //

// Dev server Urls starts //

/* export const URL = 'https://erps-test.mycw.in/cw-retail/graphql'
export const GENERICURL ='https://erps-test.mycw.in/back-office-2.0/graphql'
export const Auth = 'https://identity-test.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://erps-test.mycw.in:8443/' */

// Dev server Urls ends //

// Pre prod Urls starts 

/* export const URL = 'https://s360app.mycw.in/cw-retail/graphql'
export const GENERICURL ='https://sapp.mycw.in/services/graphql'
export const Auth ='https://s360app.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://sapp.mycw.in:8443/' */

// Pre prod Urls ends

// demo server Urls starts

/* export const URL = 'https://s2demos.cw.solutions/cw-retail/graphql'
export const GENERICURL ='https://sdemos.cw.solutions/back-office-2.0/graphql'
export const Auth =' https://identity-demos.cw.solutions/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://sdemos.cw.solutions:8443' */

// demo server Urls ends

// cwone server Urls starts

/* export const URL = 'https://s360one.mycw.in/cw-retail/graphql'
export const GENERICURL ='https://sone.mycw.in/back-office-2.0/graphql'
export const Auth =' https://sone.mycw.in/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://sdemos.cw.solutions:8443' */

// cwon server Urls ends

// s01  Urls starts 

/* export const URL = 'https://s01core.cw.solutions/cw-retail/graphql'
export const GENERICURL ='https://s01core.cw.solutions/back-office-2.0/graphql'
export const Auth ='https://s01auth.cw.solutions/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://s01core.cw.solutions:8444/' */

// s01 Urls ends

// s02  Urls starts 

/* export const URL = 'https://s02core.cw.solutions/cw-retail/graphql'
export const GENERICURL ='https://s02core.cw.solutions/back-office-2.0/graphql'
export const Auth ='https://s02auth.cw.solutions/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://s02core.cw.solutions:8445/' */

// s02 Urls ends

// s03  Urls starts 

/* export const URL = 'https://s03core.cw.solutions/cw-retail/graphql'
export const GENERICURL ='https://s03core.cw.solutions/back-office-2.0/graphql'
export const Auth ='https://s03auth.cw.solutions/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://s03core.cw.solutions:8446/' */

// s03 Urls ends

// Anandyas Urls start
export const URL = 'https://s2anandyas.cw.solutions/cw-retail/graphql'
export const GENERICURL ='https://erp2anandyas.cw.solutions/back-office-2.0/graphql'
export const Auth ='https://ianandyas.cw.solutions/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://s2anandyas.cw.solutions:8443/'
// Anandyas Urls end
export const UserId = '100'
